.anketablock{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background-color:#fff;
 margin: 10px;
}
.enterdata{
  padding:10px;
}
.show
{
  display:inline-block;
  padding:10px;
  
}
input{
  margin: 5px;
}

.nodisplay{
  display:none;
}