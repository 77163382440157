.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.wrapper {
  display: flex;
  width: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: auto;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: url("https://puhliykrolik.ru/images/header.jpg");
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
  height: 100%; */
}
.main li {
  width: 100%;
}
.gradient {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 0;
  left: 0; */
  background: linear-gradient(to bottom, #f4f5f7, #dce6ef);
  opacity: 0.85;
  z-index: -1;
}
/* .Nav {
  width: 200px;
} */
.Content {
  max-width: 800px;
  height: 100%;
  width: 100%;
}
.Header {
  width: 100%;
  /* min-height: 100px; */
}
.Footer {
  width: 100%;
  /* min-height: 100px; */
}
@media (max-width: 900px) {
  .Main {
    flex-direction: column-reverse;
  }
  .Nav {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .container {
    align-items: flex-start;
  }
}
