.previewComponent {
  position: relative;
}
.button__image_upload {
  position: absolute;
  top: 27px;
  left: 30px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  color: #fff;

  border-color: #2e6da4;
  padding: 5px;
  background: #337ab7 no-repeat url("http://localhost:3000/images/attach.png");
  background-position: center;
  padding: 0 20px;
  height: 30px;
  width: 30px;
  margin: 5px;
  opacity: 0.5;
}
.button__image_upload:hover {
  opacity: 0.8;
}
.button {
  background-color: #195588;
  padding: 5px;
  color: white;
}

#image_uploads {
  opacity: 0;
}
/* .fileInput{
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
} */

.imgPreview {
  text-align: center;
  margin: 5px 15px;
  width: 300px;
  max-height: 200px;
  max-width: 500px;
  /* min-height: 200px;
  min-width: 200px; */
  /* border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray; */
}
.imgPreview img {
  width: 90%;
}

.previewText {
  width: 100%;
  margin-top: 20px;
}

.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
}
&:hover {
  background: #efefef;
}

.centerText {
  text-align: center;
  width: 500px;
}
