.registrationform {
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 20px;
}
.registrationform form {
  max-width: 500px;
  margin: 0 auto;
}

.registrationform .form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

.registrationform p {
  color: #bf1650;
  position: relative;
  top: -10px;
  left: 10px;
}

.registrationform p::before {
  display: inline;
  content: "⚠ ";
}

.registrationform input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #bf1650;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.registrationform label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}
.registrationform span {
  line-height: 2;
  padding: 10px;
}

.registrationform button[type="submit"],
.registrationform input[type="submit"] {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  position: relative;
}

.registrationform button[type="submit"]:hover,
.registrationform input[type="submit"]:hover {
  background: #bf1650;
}

.registrationform button[type="submit"]:active,
.registrationform input[type="button"]:active,
.registrationform input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.registrationform input:disabled {
  opacity: 0.4;
}

.registrationform input[type="button"]:hover {
  transition: 0.3s all;
}

.registrationform button[type="submit"],
.registrationform input[type="button"],
.registrationform input[type="submit"] {
  -webkit-appearance: none;
}

.registrationform button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.registrationform hr {
  margin-top: 30px;
}

.registrationform button {
  width: 100%;
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}
