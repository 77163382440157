.show, .enterdata {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  /* display:inline-block; */
  padding: 10px;
  min-width: 400px;
}
.panel {

}
input {
  margin: 5px;
}

.nodisplay {
  display: none;
}
.admin_pay_button{
  width: 100%;
border-width: 2px;
border-color: thistle;
border-radius: 5px;
}