* {
  box-sizing: border-box;
}
body {
  width: 100%;
  font-size: 10px;
  font-family: "Poiret One", Arial, sans-serif;
}
@font-face {
  font-family: "OpenSansCondensed";
  src: url("OpenSansCondensed-Light.woff2") format("woff2"),
    url("OpenSansCondensed-Light.ttf") format("truetype"),
    /* Только если нужна поддержка старых Android, иначе закомментировать */
      url("OpenSansCondensed-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  color: rgb(134, 31, 62);
}
.small-link:hover {
  text-decoration: underline;
}
a[href$=".pdf"]:before {
  content: url("https://puhliykrolik.ru/images/pdf.png");
  position: relative;
  left: -10px;
  top: 30px;
  opacity: 0.7;
}
a[href$=".doc"],
a[href$=".docx"]:before {
  content: url("https://puhliykrolik.ru/images/word.png");
  position: relative;
  left: -10px;
}
.info-text {
  font-size: 1.3rem;
  font-family: "Poiret One", Arial, sans-serif;
  width: 80%;
  font-weight: 600;
}
.top-banner {
  position: sticky;
  top: 0.5rem;
  z-index: 100;
}
h1 {
  font-size: 2rem;
}
.info-text h1,
h2,
h3 {
  font-size: 2rem;
  font-weight: 700;

  line-height: 2;
}
.info-text li {
  line-height: 1.3;
}
.info-text ul {
  margin-left: 30px;
}

.info-text ul li {
  list-style: circle;
}
.tutorials {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.tutorials-list {
  width: 100%;
  max-width: 1200px;
}
.tutorials-list ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2rem;
}
.tutorials-list h3 {
  font-size: 2rem;
  text-align: center;
  padding-top: 20px;
}
.tutorial-container {
  width: 100%;
}
.tutorial_doc {
  display: flex;

  height: 170px;
  /* width: 500px; */
  font-size: 1.5rem;
  transition: 1s;
}
.tutorial_doc:hover {
  text-shadow: 1px 1px 2px rgb(82, 166, 245), 0 0 1em blue, 0 0 0.2em blue;
}

/* hr {
  margin: 25px 0 40px 0;
  color: #333;
  background-color: #333;
  padding: 0;
  height: 0;
  border: none;
  border-top: 1px solid #333;
  transform: rotate(-2deg);
} */
h1,
h2,
h3 {
  margin: 0;
}

input {
  width: 100%;
}
input[type="email"],
input[type="password"],
input[type="username"] {
  border: 0;
  border-bottom: 1px solid;
  margin: 5px;
}
input[type="submit"] {
  border: 1px solid;
  border-radius: 5px;
  background-color: #f0c6c5;
  margin: 5px;
  padding: 5px;
  transition: 0.3s;
}
.button {
  border: 1px solid;
  border-radius: 5px;
  background-color: #f0c6c5;
  margin: 5px;
  padding: 5px;
  transition: 0.3s;
}
.button:active {
  background-color: #ee8382;
}
.header {
  height: 300px;
  background-color: #ffffff;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  background-image: url("https://puhliykrolik.ru/images/header.jpg");
  position: relative;
}
.login-form {
  /* position: absolute;
  right: -100px; */

  display: flex;
  align-items: center;
  top: 20 px;
  /* background-color: #ffffff; */
  opacity: 0.5;

  line-height: 1.3;
  /* transition: 1s; */
  border-radius: 10px;
  /* padding: 10px; */
}
.login-form b {
  margin: 0 4px 0 4px;
}
/* .login-form:hover {
  right: 20px;
  opacity: 0.8;
} */
.menu-main .button {
  padding: 10px;
}
.header-wrapper {
  z-index: 3;
  top: 0px;
  left: calc(50% - 50px + 0px);
  width: 100%;
  height: 534px;
}
.header-upwrapper {
  background-color: #dce6ef;
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
}
.header-element {
  position: absolute;
  /* box-sizing: border-box; */
  display: table;
}
.logo-wrapper {
  width: 320px;
  left: 101.5px;
  top: 150px;
  height: 300px;
  z-index: 8;
  position: absolute;
  /* box-sizing: border-box; */
  display: table;
  z-index: 51;
}
.logo {
  background-image: url("https://puhliykrolik.ru/images/logo.jpg");
  border-width: 2px;
  border-radius: 3000px;
  background-color: #ffffff;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: #b7647e;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.slogan-wrapper {
  top: 290px;
  left: 500px;
  width: calc(100% - 500px);
  height: 300px;
  color: #000000;
  z-index: 6;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slogan {
  padding-left: 30px;
  color: #000000;
  font-size: 2.5rem;
  font-family: "Poiret One", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  width: 100%;
  height: 100%;
}
.menu-wrapper {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
li,
ul {
  list-style: none;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 10px;
}

.nodisplay {
  display: none;
}
.description-item {
  position: relative;
  margin: 20px 0 0 50px;
}
.description-item::before {
  max-height: 50px;
  left: -50px;
  top: 10px;
  content: "";
  position: absolute;
  height: 50px;
  width: 50px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  background-image: url("https://puhliykrolik.ru/images/-1.png");
  background-size: contain;
  border-color: transparent;
  border-style: solid;
  background-repeat: no-repeat;
  display: block;
  /* vertical-align: middle; */
  -webkit-text-size-adjust: 100%;
}
.tutorial-description {
  padding: 20px;
  display: block;
  border-width: 1px;
  border-radius: 20px;
  background-color: #f3dcdc;
  border-color: #f09d9c;
  border-style: solid;
}

.tutorial-price {
  padding: 20px;
  display: block;
  font-size: 2rem;
  /* border-width: 1px;
  border-radius: 20px; */
  /* background-color: #f3dcdc; */
  /* border-color: #f09d9c;
  border-style: solid; */
}
.payed {
  position: relative;
  margin: 20px 0 0 100px;
  color: #000000;
  display: block;
  width: 300px;
  padding: 15px;
  font-size: 1.7rem;

  line-height: 1;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-width: 1px;
  border-radius: 20px;
  background-color: #f0c6c5;
  background-position: center center;
  border-color: #000000;
  border-style: solid;
  text-align: center;
  transition: background-color 0.6s ease-in-out, color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
}
a .payed {
  text-decoration: none;
  color: #000000;
}

.pay-button {
  position: relative;
  margin: 70px 0 0 100px;
  color: #000000;
  display: block;
  width: 200px;
  padding: 15px;
  font-size: 1.7rem;

  line-height: 1;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-width: 1px;
  border-radius: 20px;
  background-color: #f0c6c5;
  background-position: center center;
  border-color: #000000;
  border-style: solid;
  text-align: center;
  transition: background-color 0.6s ease-in-out, color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
}
.pay-button a {
  color: #000;
  text-decoration: none;
}

.pay-button::before {
  left: -120px;
  top: -70px;
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  background-image: url("https://puhliykrolik.ru/images/-1.png");
  background-size: contain;
  border-color: transparent;
  border-style: solid;
  background-repeat: no-repeat;
  display: block;
  /* vertical-align: middle; */
  -webkit-text-size-adjust: 100%;
}
.pay-button:hover {
  background-color: #c9e9b0;
}
.pay-button-prev {
  margin-left: 30px;
  color: #000000;
  font-size: 1.7rem;

  line-height: 1;
  font-weight: 500;
  letter-spacing: 1.5px;
}
.tutorial-title {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 100px;
  text-align: center;
}
.tutorial-title:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100px;
  opacity: 0.75;
  background-image: url(https://puhliykrolik.ru/images/header.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: transparent;
}
.tutorial-title h1 {
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.item-title {
  width: 100%;
  text-align: left;
  font-family: "Playfair Display", serif;
  font-size: 1.2rem;
  padding: 10px;
  text-align: center;
}
.tutorial-card {
  color: #645858;
  font-size: 1.2rem;
  font-family: "Poiret One", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 30px;
}
.tutorial-card h2,
.tutorial-card h3 {
  color: #000000;

  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2;
}
.tutorial-card p {
  padding: 20px;
}
.tutorial-item {
  display: block;
  color: #000000;
  font-size: 1.3rem;
  /* font-family: "Montserrat", Arial, sans-serif; */
  line-height: 1.55;
  font-weight: 400;
  border: #645858 1px solid;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
  padding: 10px 30px;
  cursor: pointer;
}
.tutorial-item h2 {
  font-size: 2rem;
}
a .tutorial-item h2 {
  text-decoration: none;
}
.tutorial-item li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-item ul {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.tutorial-item:hover {
  background: rgba(34, 34, 34, 0.2);
  z-index: 5;
}
.tutorial-card .leftside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tutorial-card .leftside img {
  max-width: 400px;
  height: auto;
}
.tutorial-card a {
  text-decoration: none;
}
.tutorial-card .rightside {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.tutorial-card .rightside h2 {
  font-size: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tutorial-picture {
  position: relative;
  width: 400px;
  height: 400px;
}
/* .item-picture {
  max-height: 200px;
  max-width: 200px;
} */
.tutorial-picture img {
  position: absolute;
  left: 10px;
  top: -4px;
  max-height: 200px;
}
.attention {
  position: relative;
  color: #000000;
  font-size: 1.8rem;
  /* font-family: "Playfair Display", serif; */
  line-height: 1;
  font-weight: 900;
  letter-spacing: 1.5px;
  margin: 30px 0 0 120px;
}
.attention::before {
  left: -120px;
  top: -30px;
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;

  background-image: url("https://puhliykrolik.ru/images/-2.png");
  background-size: contain;
  border-color: transparent;
  border-style: solid;
  background-repeat: no-repeat;
  display: block;
  /* vertical-align: middle; */
  -webkit-text-size-adjust: 100%;
}
.player-wrapper {
  position: relative;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.footer {
  width: 100%;
  background-color: #dce6ef;
  background-image: url("https://puhliykrolik.ru/images/header.jpg");
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.2rem;
  align-items: center;
  /* font-weight: bold; */
}
.link-dark {
  font-size: 1.1rem;
  font-family: "Poiret One", Arial, sans-serif;
  text-decoration: none;
  font-weight: bold;
  margin-right: 10px;
}
.link-dark:hover {
  text-shadow: #fc0 1px 0 10px;
}
.tutorial-admin-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: whitesmoke;
  border: thistle;
  border-width: 2px;
  border-radius: 4px;
}
.delete__button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #337ab7 no-repeat url("https://puhliykrolik.ru/images/delete.png");
  background-position: center;
  padding: 0 20px;
  /* width: 50px; */
  height: 30px;
  color: #fff;
  margin-left: 15px;
  opacity: 0.4;
}
.delete__button:hover {
  opacity: 1;
}
.tutorial_img {
  border-radius: 2px;
  margin-left: 10px;
  max-width: 400px;
  max-height: 400px;
  width: auto;
  height: auto;
}
.picture {
  position: relative;
}
.info {
  font-size: 1.4rem;
  font-weight: 700;
}
.info li,
ul {
  list-style: circle;
  padding-left: 30px;
}

.top-menu {
  position: relative;
  background: rgba(34, 34, 34, 0.2);
  width: 100%;
  z-index: 50;
}
.menu-main {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.menu-main:after {
  content: "";
  display: table;
  clear: both;
}
.left-item {
  float: left;
}
.right-item {
  float: right;
}
/* .navbar-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */
.menu-main a {
  text-decoration: none;
  display: block;
  line-height: 50px;
  padding: 0 20px;
  font-size: 1.3rem;
  letter-spacing: 2px;
  font-family: "Poiret One", Arial, sans-serif;
  font-weight: bold;
  color: #b81e4f;
  transition: 0.3s linear;
}
.menu-main a:hover {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

@media screen and (max-width: 1130px) {
  body {
    font-size: 7px;
  }
  .header {
    height: 200px;
  }
  .logo-wrapper {
    width: 100px;
    left: 101.5px;
    top: 150px;
    height: 100px;
  }
  .logo {
    background-image: url("https://puhliykrolik.ru/images/logo.jpg");
    border-width: 2px;
    border-radius: 1000px;
  }
  .slogan-wrapper {
    top: 200px;
    left: 200px;
    width: 100%;
    height: 200px;
  }
  .slogan {
    color: #000000;
    line-height: 1.3;
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 7px;
  }
  .menu-wrapper {
    height: auto;
  }
  .slogan {
    font-size: 2rem;
  }
  .menu-main {
    justify-content: space-between;
    padding-left: 30px;
  }
  .tutorial-card {
    color: #000000;
    /* font-size: 26px; */
    /* font-family: "Montserrat", Arial, sans-serif; */
    line-height: 1.55;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .tutorial-title h1 {
    font-size: 1.5rem;
  }
  .header-upwrapper {
    height: 350px;
  }

  .tutorial-card .leftside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .attention {
    margin: 10px 0;
    border-radius: 5px;
    padding: 5px;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 20px;
    color: red;
  }
  .attention::before {
    display: none;
  }
  .menu-main {
    padding-top: 90px;
    text-align: center;
  }
  .navbar-logo {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
  .menu-main li {
    float: none;
    display: inline-block;
  }
  .menu-main a {
    line-height: normal;
    padding: 0;
    font-size: 1rem;
  }
  .footer {
    flex-direction: column;
    width: 100%;
  }
}
@media (max-width: 630px) {
  .menu-main li {
    display: block;
  }
  .slogan-wrapper {
    top: 250px;
    left: 10px;
    width: 100%;
    height: 200px;
  }
  .slogan {
    padding-left: 10px;
  }
  .tutorial_doc {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  body {
    font-size: 6px;
  }
  .menu-main {
    padding-left: 10px;
  }
  .slogan {
    font-size: 1.7rem;
  }
  .menu-main a {
    font-size: 0.9rem;
    margin-left: 10px;
  }
  .tutorial-card p {
    padding: 20px;
    font-size: 1rem;
  }
  .tutorial-description {
    font-size: 1rem;
  }
  .tutorial-price {
    font-size: 1.2rem;
  }
}
@media (max-width: 400px) {
  body {
    font-size: 5px;
  }
  .tutorial-picture {
    position: relative;
    width: 100vw;
    height: 100vw;
  }
  .tutorial_doc {
    font-size: 0.8rem;
  }
}
